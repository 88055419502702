import CookieAccept from '@components/CookieAccept';
import SwitchToTraqqLayout from '@layouts/Ads/SwitchToTraqqLayout';
import GetOnTraqqLayout from '@layouts/Ads/GetOnTraqqLayout';
import Footer from '@layouts/Footer';
import RenderIf from '@utils/renderIf';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import Seo from './Seo';

const Layout = ({ children, title, description, useTitleTemplate, additionalMetaTags, defaultSeoConfig }) => {
    const { pathname } = useRouter();

    return (
        <>
            <Seo
                title={ title }
                description={ description }
                useTitleTemplate={ useTitleTemplate }
                additionalMetaTags={ additionalMetaTags }
                defaultSeoConfig={ defaultSeoConfig }
            />

            { children }

            <RenderIf
                isTrue={ pathname !== '/' && ![
                    'pricing',
                    'support',
                    'about',
                    'contact',
                    'blog',
                    'demo',
                    'switch-to-traqq-discount',
                    'legal',
                    'terms',
                    'privacy',
                    '404', '500', '400', '401', '403', '502', '503', '504',
                    'action-forbidden',
                    'land/enterprise-time-tracking',
                    'land/time-tracking-solution',
                    'feedback',
                    'online-timer',
                    'online-stopwatch',
                    'pomodoro-timer'
                ].some(item => pathname.includes(item)) }
            >
                <SwitchToTraqqLayout/>
            </RenderIf>

            <RenderIf
                isTrue={ ['pricing'].some(item => pathname.includes(item)) }
            >
                <GetOnTraqqLayout/>
            </RenderIf>

            <RenderIf
                isTrue={ !['404', '500', '400', '401', '403', '502', '503', '504', 'action-forbidden', 'land/enterprise-time-tracking', 'land/time-tracking-solution', 'land/effective-time-tracker'].some(item => pathname.includes(item)) }
            >
                <Footer/>
            </RenderIf>

            <CookieAccept/>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
    defaultSeoConfig: PropTypes.object,
    description: PropTypes.string,
    title: PropTypes.string,
    useTitleTemplate: PropTypes.string
};

Layout.defaultProps = {
    useTitleTemplate: 'Traqq'
};

export default Layout;
