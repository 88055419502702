import Icon from '@components/UI/Icon';
import css from '@utils/css'
import useMods from '@hooks/useMods';

import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Button = forwardRef(({
    children,
    mods,
    mix,
    tag,
    href,
    type,
    target,
    variant,
    color,
    size,
    disabled,
    buttonProps,
    onClick,
    onMouseEnter,
    onMouseDown,
    onMouseLeave,
    id,
    className: classNameTail
}, ref) => {
    const { className } = useMods(Button, { mix, mods: { ...mods, color, size, variant } }, true);
    const TagName = tag;

    const cn = css(className, classNameTail)

    return (
        type === 'link' ? (
            <Link
                href={ href }
                ref={ ref }
                prefetch={ false }
            >
                { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                <a
                    id={ id }
                    className={ cn }
                    target={ target }
                    { ...buttonProps }
                >
                    { children }
                    { variant === 'link' && <Icon
                        name='solid-circle-chevron-right'
                        color={ ['violet-700', '#ffffff'] }
                        size={ 20 }
                    /> }
                </a>
            </Link>
        ): (
            <TagName
                id={ id }
                { ...(type && !href ? { type } : null) }
                className={ cn }
                target={ target }
                disabled={ disabled }
                { ...buttonProps }
                onClick={ onClick }
                onMouseEnter={ onMouseEnter }
                onMouseDown={ onMouseDown }
                onMouseLeave={ onMouseLeave }
                ref={ ref }
            >
                { children }
            </TagName>
        )
    );
});

Button.displayName = 'Button';

Button.propTypes = {
    buttonProps: PropTypes.object,
    children: PropTypes.node,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    mix: PropTypes.object,
    mods: PropTypes.object,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    size: PropTypes.string,
    tag: PropTypes.string,
    target: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string
};

Button.defaultProps = {
    disabled: false,
    mix: {},
    mods: {},
    tag: 'button',
    type: 'button'
};

export default Button;
